header {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: var(--base-color);
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-dim);
  .toggle {
    position: relative;
    width: 30px;
    cursor: pointer;
    svg {
      width: 30px;
      path {
        fill: var(--text-max);
      }
    }
  }
  .profile-dd {
    .dropdown-toggle {
      background-color: var(--hover-color);
      padding: 5px 10px;
      border-radius: 5px;
      user-select: none;
    }
  }
}
